import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const DynamicCarousel = ({ items }) => {
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      showDots={true}
      containerClass="wide-slider-testimonial"
    >
      {items.map((item, index) => (
        <div className="item" key={index}>
          <blockquote className="block-testimonial">
            <p dangerouslySetInnerHTML={{ __html: item.quote }} />
            <div className="author">
              <img src={item.image} alt="Free template by TemplateUX" />
              <h3>{item.author}</h3>
              <p className="position">{item.position}</p>
            </div>
          </blockquote>
        </div>
      ))}
    </Carousel>
  );
};

export default DynamicCarousel;
