import React, { useState } from "react";

const AccordionItem = ({ id, title, imageSrc, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <h2 className="mb-0">
        <button
          className={`btn btn-link ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={toggleAccordion}
          aria-expanded={isOpen ? "true" : "false"}
          aria-controls={`collapse_${id}`}
        >
          {title}
        </button>
      </h2>
      <div
        id={`collapse_${id}`}
        className={`collapse${isOpen ? "show" : ""}`}
        aria-labelledby={`heading_${id}`}
        data-parent="#accordion_1"
      >
        <div className="accordion-body">
          <div className="d-flex">
            <div className="accordion-img mr-4">
              <img src={imageSrc} alt="img" className="img-fluid" />
            </div>
            <div>
              {text.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DynamicAccordion = ({ items }) => {
  return (
    <div className="custom-accordion" >
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          id={index + 1}
          title={item.title}
          imageSrc={item.imageSrc}
          text={item.text}
        />
      ))}
    </div>
  );
};

export default DynamicAccordion;
