import { AiFillCustomerService } from "react-icons/ai";
import { HiOutlineSpeakerphone, HiPhoneIncoming } from "react-icons/hi";
import { IoIosHelpCircle, IoIosVideocam } from "react-icons/io";
import { LuLanguages } from "react-icons/lu";
import { MdLeaderboard, MdOutlineSupportAgent } from "react-icons/md";
import { VscServerProcess } from "react-icons/vsc";

export const servicesListing=[
    {
        heading:"Inbound Customer Support",
        description:"Provide assistance to customers who reach out with inquiries, issues, or feedback regarding products or services. Our skilled agents ensure prompt and effective resolution to enhance customer satisfaction and loyalty.",
        icon:<HiPhoneIncoming className="uil"/>
    },
    {
        heading:"Outbound Sales Calls",
        description:"Reach out to potential customers through proactive outbound calling campaigns. Our sales agents engage prospects, qualify leads, and promote products or services to drive sales and revenue growth.",
        icon:<HiOutlineSpeakerphone className="uil" />
    },
    {
        heading:"Technical Support",
        description:"Resolve technical issues and provide troubleshooting assistance to customers facing product or service-related problems. Our knowledgeable support team ensures timely resolution and minimizes downtime for uninterrupted operations.",
        icon:<MdOutlineSupportAgent className="uil" />
    },
    {
        heading:"Lead Generation",
        description:"Identify and qualify potential leads through targeted outbound calling efforts. Our lead generation specialists engage prospects, gather relevant information, and nurture leads to facilitate the sales process and maximize conversion rates.",
        icon:<MdLeaderboard className="uil" />
    },
    {
        heading:"Appointment Setting",
        description:"Schedule appointments and meetings on behalf of clients to streamline their sales or service processes. Our appointment setters liaise with leads or customers to coordinate schedules and ensure successful bookings.",
        icon:<IoIosVideocam className="uil" />
    },
    {
        heading:"Order Taking and Processing",
        description:"Receive and process orders from customers via phone or other communication channels. Our order processing agents ensure accurate order entry, address customer inquiries, and facilitate smooth order fulfillment to enhance customer satisfaction.",
        icon:<VscServerProcess className="uil" />
    },
    {
        heading:"Customer Retention Campaigns",
        description:"Implement targeted campaigns to retain existing customers and reduce churn rates. Our retention specialists engage with customers, address concerns, and offer personalized incentives or solutions to foster long-term loyalty and repeat business.",
        icon:<AiFillCustomerService className="uil" />
    },
    {
        heading:"Help Desk Services",
        description:"Provide comprehensive help desk support to assist users with technical or operational issues related to software, hardware, or systems. Our help desk agents offer timely assistance and guidance to resolve issues and optimize user experience.",
        icon:<IoIosHelpCircle className="uil" />
    },
    {
        heading:"Multilingual Support",
        description:"Cater to diverse customer bases by offering support services in multiple languages. Our multilingual agents communicate fluently in various languages to ensure effective communication and support for customers worldwide.",
        icon:<LuLanguages  className="uil" />
    },
]