import { FaSellsy, FaWebflow } from "react-icons/fa6"
import { HiOutlineSpeakerphone, HiPhoneIncoming,} from "react-icons/hi"
import { MdHealthAndSafety, MdOutlineSocialDistance } from "react-icons/md"

export const leadsLabels=[
    'We provide services for Quality Leads',
    1000, 
    'We provide services for Sales Leads',
    1000,
    'We provide services for Web Leads',
    1000,
    'We provide services for Digital Marketing Leads',
    1000,
    'We provide services for Inbound Leads',
    1000,
    'We provide services for Outbound Leads',
    1000,
    'We provide services for InsuranceLeads',
    1000
]
export const allLeadsList=[
    {
        name:"Digital Marketing Leads",
        icon:<MdOutlineSocialDistance className="uil" />
    },
    {
        name:"Out-bound Leads",
        icon:<HiOutlineSpeakerphone className="uil" />
    },
    {
        name:"In-bound Leads",
        icon:<HiPhoneIncoming className="uil" />
    },
    {
        name:"Web Devlopment Leads",
        icon:<FaWebflow className="uil" />
    },
    {
        name:"Insurance Leads",
        icon:<MdHealthAndSafety className="uil" />
    },
    {
        name:"Sales Leads",
        icon:<FaSellsy className="uil" />
    }
]
export const testimonialItems= [
    {
      quote:
        "&ldquo;Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.&rdquo;",
      author: "John Doe",
      position: "CEO, Founder",
      image: "images/person_1.jpg",
    },
    {
      quote:
        "&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own road, the Line Lane. Pityful a rethoric question ran over her cheek, then she continued her way.&rdquo;",
      author: "James Woodland",
      position: "Designer at Facebook",
      image: "images/person_2.jpg",
    },
    {
      quote:
        "&ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;",
      author: "Rob Smith",
      position: "Product Designer at Twitter",
      image: "images/person_3.jpg",
    },
  ];
export  const homeAccordionItems = [
    {
      title: 'Experienced Professionals',
      imageSrc: 'images/img-choose-us-1.avif',
      text: [
        'Our team consists of highly skilled and experienced professionals with years of expertise in the call center industry. We understand the nuances of customer service and are committed to delivering exceptional results.'
      ]
    },
    {
      title: 'Customized Solutions',
      imageSrc: 'images/img-choose-us-2.avif',
      text: [
        'Equipped with state-of-the-art technology and tools, we ensure seamless communication and efficient operations. Our advanced systems enable us to streamline processes, optimize performance, and deliver superior service to our clients and their customers.'
      ]
    },
    {
      title: 'Cutting-edge Technology',
      imageSrc: 'images/img-choose-us-3.jpg',
      text: [
        'Equipped with state-of-the-art technology and tools, we ensure seamless communication and efficient operations. Our advanced systems enable us to streamline processes, optimize performance, and deliver superior service to our clients and their customers.'
      ]
    },
    {
      title: 'Focus on Quality',
      imageSrc: 'images/img-choose-us-4.png',
      text: [
        'Quality is at the heart of everything we do. From hiring the best talent to implementing rigorous quality assurance measures, we are dedicated to maintaining high standards of service excellence. Our commitment to quality ensures that every interaction with your customers is professional, efficient, and memorable.'
      ]
    }
  ];

  export const homeAboutListing=[
    {
        text:"Industry Experience"
    },
    {
        text:"Client Success Stories"
    },
    {
        text:"Awards and Recognition"
    },
    {
        text:"Company Culture and Values"
    },
  ]
  export const homeAboutCounterListing=[
    {
        heading:"No. Employees",
        start:0,
        end:100,
        text:"+"
    },
    {
        heading:"No. Clients",
        start:0,
        end:500
    },
    {
        heading:"Success Rate",
        start:0,
        end:100,
        text:"%"
    },
    
  ]
