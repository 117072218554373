import React, { useRef } from "react";

const Contact = () => {
  const sectionRef = useRef(null);

  const handleScrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div
        className="untree_co-hero overlay"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/img-contact-us-1.jpg)`,
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1
                    className="mb-4 heading text-white"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Contact Us
                  </h1>
                  <div
                    className="mb-5 text-white desc mx-auto"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h4>
                      You can reach us via phone, email, or visit our office.
                      Our team is available to assist you with any queries you
                      may have.
                    </h4>
                  </div>

                  <p className="mb-0" data-aos="fade-up" data-aos-delay="300">
                    <a
                      href="#"
                      className="btn btn-secondary"
                      onClick={handleScrollToSection}
                    >
                      Explore More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="untree_co-section" ref={sectionRef}>
        <div className="container">
          <div className="row mb-5">
            <div
              className="col-lg-4 mb-5 order-2 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="contact-info">
                <div className="address mt-4">
                  <i className="icon-room"></i>
                  <h4 className="mb-2">Location:</h4>
                  <p>43 Raymouth Rd. Baltemoer, London 3910</p>
                </div>

                <div className="open-hours mt-4">
                  <i className="icon-clock-o"></i>
                  <h4 className="mb-2">Open Hours:</h4>
                  <p>
                    Sunday-Friday:
                    <br />
                    11:00 AM - 2300 PM
                  </p>
                </div>

                <div className="email mt-4">
                  <i className="icon-envelope"></i>
                  <h4 className="mb-2">Email:</h4>
                  <p>info@theoriginate.com</p>
                </div>

                <div className="phone mt-4">
                  <i className="icon-phone"></i>
                  <h4 className="mb-2">Call:</h4>
                  <p>+1 1234 55488 55</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 mr-auto order-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <form action="#">
                <div className="row">
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="7"
                      className="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>

                  <div className="col-12">
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
