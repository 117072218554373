import React, { useRef } from "react";
import { servicesListing } from "../_mock_data/ServicesData";
import Pricing from "../Pricing/Pricing";

const Services = () => {
  const sectionRef = useRef(null);

  const handleScrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div
        className="untree_co-hero overlay"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/img-services.webp)`,
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1
                    className="mb-4 heading text-white"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Services
                  </h1>
                  <div
                    className="mb-5 text-white desc mx-auto"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h4>
                      At The Originate, we offer a comprehensive range of
                      solutions designed to meet your unique needs and exceed
                      your expectations. Explore our services below to discover
                      how we can help you achieve your goals.
                    </h4>
                  </div>

                  <p className="mb-0" data-aos="fade-up" data-aos-delay="300">
                    <a
                      href="#"
                      className="btn btn-secondary"
                      onClick={handleScrollToSection}
                    >
                      Explore More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="untree_co-section bg-light" ref={sectionRef}>
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div
              className="col-lg-7 text-center"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <h2 className="line-bottom text-center mb-4">Services</h2>
              <p>
                From lead generation and appointment setting to order taking and
                customer retention campaigns, we provide efficient solutions to
                enhance your customer experience and drive business growth.
              </p>
            </div>
          </div>
          <div className="row">
            {servicesListing?.map((data, index) => (
              <div
                className="col-6 col-sm-6 col-md-6 col-lg-4"
                data-aos="fade-up"
                data-aos-delay="100"
                key={index}
              >
                <div className="feature">
                  <span style={{ fontSize: "25px", color: "#136ad5" }}>
                    {data?.icon}
                  </span>
                  <h3>{data?.heading}</h3>
                  <p>{data?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Pricing />
    </>
  );
};

export default Services;
