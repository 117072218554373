import { useState, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';

const TextAnimator = ({ sequence,color }) => {
  const [animationSequence, setAnimationSequence] = useState(sequence);

  useEffect(() => {
    // Update animation sequence dynamically if provided
    if (sequence && sequence.length > 0) {
      setAnimationSequence(sequence);
    }
  }, [sequence]);

  return (
    <TypeAnimation
      sequence={animationSequence}
      wrapper="span"
      speed={30}
      style={{ fontSize:"45px",color:color,fontWeight:"700",fontFamily:"Display Playfair, serif"}}
      repeat={Infinity}
    />
  );
};

export default TextAnimator;
