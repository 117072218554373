import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [scrollCheck, setScrollCheck] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [linkCheck, setLinkCheck] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollCheck(true);
      } else {
        setScrollCheck(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      {isOpen && (
        <div className={isOpen && "site-mobile-menu"}>
          <div className="site-mobile-menu-header">
            <div
              className="site-mobile-menu-close"
              onClick={() => setIsOpen(false)}
            >
              <span className="icofont-close js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body">
            <ul className="site-nav-wrap">
              <li
                className={linkCheck === "home" && "active"}
                onClick={() => setLinkCheck("home")}
              >
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              {
                // <li className="has-children">
                //   <a href="#">Dropdown</a>
                //   <ul className="dropdown">
                //     <li>
                //       <a href="elements.html">Elements</a>
                //     </li>
                //     <li className="has-children">
                //       <a href="#">Menu Two</a>
                //       <ul className="dropdown">
                //         <li>
                //           <a href="#">Sub Menu One</a>
                //         </li>
                //         <li>
                //           <a href="#">Sub Menu Two</a>
                //         </li>
                //         <li>
                //           <a href="#">Sub Menu Three</a>
                //         </li>
                //       </ul>
                //     </li>
                //     <li>
                //       <a href="#">Menu Three</a>
                //     </li>
                //   </ul>
                // </li>
              }
              {
                // <li>
                //   <a href="staff.html">Our Staff</a>
                // </li>
              }
              <li
                className={linkCheck === "services" && "active"}
                onClick={() => setLinkCheck("services")}
              >
                <a onClick={() => navigate("/services")}>Services</a>
              </li>
              {
                // <li>
                //   <a href="gallery.html">Gallery</a>
                // </li>
              }
              <li
                className={linkCheck === "about" && "active"}
                onClick={() => setLinkCheck("about")}
              >
                <a onClick={() => navigate("/about")}>About Us</a>
              </li>
              <li
                className={linkCheck === "contact" && "active"}
                onClick={() => setLinkCheck("contact")}
              >
                <a onClick={() => navigate("/contact")}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      )}
      <nav className="site-nav mb-5">
        <div className="pb-2 top-bar mb-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6 col-lg-9">
                <a href="#" className="small mr-3">
                  <span className="icon-question-circle-o mr-2"></span>{" "}
                  <span className="d-none d-lg-inline-block">
                    Have a questions?
                  </span>
                </a>
                <a href="#" className="small mr-3">
                  <span className="icon-phone mr-2"></span>{" "}
                  <span className="d-none d-lg-inline-block">
                    10 20 123 456
                  </span>
                </a>
                <a href="#" className="small mr-3">
                  <span className="icon-envelope mr-2"></span>{" "}
                  <span className="d-none d-lg-inline-block">
                    info@theoriginate.com
                  </span>
                </a>
              </div>
              {
                // <div className="col-6 col-lg-3 text-right">
                //   <a href="login.html" className="small mr-3">
                //     <span className="icon-lock"></span>
                //     Log In
                //   </a>
                //   <a href="register.html" className="small">
                //     <span className="icon-person"></span>
                //     Register
                //   </a>
                // </div>
              }
            </div>
          </div>
        </div>
        <div
          className={
            scrollCheck ? "sticky-wrapper is-sticky" : "sticky-wrapper"
          }
          id="sticky-wrapper"
          style={{ height: scrollCheck && "150px" }}
        >
          <div
            className={
              scrollCheck
                ? "sticky-nav js-sticky-header nav-custom-class"
                : "sticky-nav js-sticky-header"
            }
          >
            <div className="container position-relative">
              <a href="/" className="menu-absolute m-0">
                <img src="images/brands/logo.png" alt="logo" className="logo" />
              </a>
              <div className="site-navigation text-center">
                <ul className="js-clone-nav d-none d-lg-inline-block site-menu mt-3">
                  <li
                    className={linkCheck === "home" && "active"}
                    onClick={() => setLinkCheck("home")}
                  >
                    <a onClick={() => navigate("/")}>Home</a>
                  </li>
                  {
                    // <li className="has-children">
                    //   <a href="#">Dropdown</a>
                    //   <ul className="dropdown">
                    //     <li>
                    //       <a href="elements.html">Elements</a>
                    //     </li>
                    //     <li className="has-children">
                    //       <a href="#">Menu Two</a>
                    //       <ul className="dropdown">
                    //         <li>
                    //           <a href="#">Sub Menu One</a>
                    //         </li>
                    //         <li>
                    //           <a href="#">Sub Menu Two</a>
                    //         </li>
                    //         <li>
                    //           <a href="#">Sub Menu Three</a>
                    //         </li>
                    //       </ul>
                    //     </li>
                    //     <li>
                    //       <a href="#">Menu Three</a>
                    //     </li>
                    //   </ul>
                    // </li>
                  }
                  {
                    // <li>
                    //   <a href="staff.html">Our Staff</a>
                    // </li>
                  }
                  <li
                    className={linkCheck === "services" && "active"}
                    onClick={() => setLinkCheck("services")}
                  >
                    <a onClick={() => navigate("/services")}>Services</a>
                  </li>
                  {
                    // <li>
                    //   <a href="gallery.html">Gallery</a>
                    // </li>
                  }
                  <li
                    className={linkCheck === "about" && "active"}
                    onClick={() => setLinkCheck("about")}
                  >
                    <a onClick={() => navigate("/about")}>About Us</a>
                  </li>
                  <li
                    className={linkCheck === "contact" && "active"}
                    onClick={() => setLinkCheck("contact")}
                  >
                    <a onClick={() => navigate("/contact")}>Contact</a>
                  </li>
                </ul>

                <a
                  onClick={() => navigate("/contact")}
                  className="btn-book btn btn-secondary btn-sm menu-absolute mt-3"
                >
                  Contact Us
                </a>

                <a
                  // href="#"
                  className="burger ml-auto float-right site-menu-toggle js-menu-toggle d-inline-block d-lg-none light mt-3"
                  onClick={() => setIsOpen(true)}
                >
                  <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
