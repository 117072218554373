import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mr-auto">
            <div className="widget">
              <h3>
                About Us<span className="text-primary">.</span>{" "}
              </h3>
              <p>
                With years of industry experience and a dedicated team of
                professionals, we are committed to providing high-quality call
                center services that exceed expectations.
              </p>
            </div>
            <div className="widget">
              <h3>Connect</h3>
              <ul className="list-unstyled social">
                <li>
                  <a href="#">
                    <span className="icon-instagram"></span>
                  </a>
                </li>
                <li className="ml-lg-2">
                  <a href="#">
                    <span className="icon-twitter"></span>
                  </a>
                </li>
                <li className="ml-lg-2">
                  <a href="#">
                    <span className="icon-facebook"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="widget">
              <h3>Quick Links</h3>
              <ul className="list-unstyled float-left links">
                <li>
                  <a onClick={() => navigate("/")}>Home</a>
                </li>
                <li>
                  <a onClick={() => navigate("/services")}>Services</a>
                </li>
                <li>
                  <a onClick={() => navigate("/about")}>About us</a>
                </li>
                <li>
                  <a onClick={() => navigate("/contact")}>Contact</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="widget">
              <h3>Contact</h3>
              <address>43 Raymouth Rd. Baltemoer, London 3910</address>
              <ul className="list-unstyled links mb-4">
                <li>
                  <a href="tel://11234567890">+1(123)-456-7890</a>
                </li>
                <li>
                  <a href="tel://11234567890">+1(123)-456-7890</a>
                </li>
                <li>
                  <a href="mailto:info@mydomain.com">info@theoriginate.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 text-center">
            <p>
              Copyright &copy;
              <script>document.write(new Date().getFullYear());</script>. All
              Rights Reserved. &mdash; Designed with love by{" "}
              <a href="https://isdsolutions.org">ISD Solutions</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
