import React, { useRef } from "react";

const AboutUs = () => {
  const sectionRef = useRef(null);

  const handleScrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className="untree_co-hero overlay"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/img-about-us-1.jpg)`,
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1
                    className="mb-4 heading text-white"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    About Us
                  </h1>
                  <div
                    className="mb-5 text-white desc mx-auto"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h4>
                      With years of industry experience and a dedicated team of
                      professionals, we are committed to providing high-quality
                      call center services that exceed expectations.
                    </h4>
                  </div>

                  <p className="mb-0" data-aos="fade-up" data-aos-delay="300">
                    <button
                      className="btn btn-secondary"
                      onClick={handleScrollToSection}
                    >
                      Explore More
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="untree_co-section bg-light" ref={sectionRef}>
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div
              className="col-lg-7 text-center"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <h2 className="line-bottom text-center mb-4">
                Our Mission and Core Values
              </h2>
              <p>
                "Our mission at The Originate is to revolutionize the call
                center industry by providing unparalleled customer service and
                support. We aim to consistently exceed client expectations,
                leveraging cutting-edge technology and a dedicated team of
                professionals. At our core, integrity, excellence, and
                innovation guide everything we do. We believe in fostering a
                culture of respect, collaboration, and continuous improvement,
                ensuring our clients receive the highest quality service while
                maintaining our commitment to ethical business practices."
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="untree_co-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div
              className="col-lg-7 text-center"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <h2 className="line-bottom text-center mb-4">Our Team</h2>
              <p>
                At The Originate, our team is the heartbeat of our operation,
                bringing together diverse talents, expertise, and passion to
                deliver exceptional service.
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 col-sm-6 col-md-6 mb-4 mb-lg-0 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <div className="staff text-center">
                <div className="mb-4">
                  <img
                    src="images/staff_1.jpg"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
                <div className="staff-body">
                  <h3 className="staff-name">Mina Collins</h3>
                  <span className="d-block position mb-4">Teacher in Math</span>
                  <p className="mb-4">
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="social">
                    <a href="#" className="mx-2">
                      <span className="icon-facebook"></span>
                    </a>
                    <a href="#" className="mx-2">
                      <span className="icon-twitter"></span>
                    </a>
                    <a href="#" className="mx-2">
                      <span className="icon-linkedin"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-md-6 mb-4 mb-lg-0 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="staff text-center">
                <div className="mb-4">
                  <img
                    src="images/staff_2.jpg"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
                <div className="staff-body">
                  <h3 className="staff-name">Anderson Matthew</h3>
                  <span className="d-block position mb-4">
                    Teacher in Music
                  </span>
                  <p className="mb-4">
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="social">
                    <a href="#" className="mx-2">
                      <span className="icon-facebook"></span>
                    </a>
                    <a href="#" className="mx-2">
                      <span className="icon-twitter"></span>
                    </a>
                    <a href="#" className="mx-2">
                      <span className="icon-linkedin"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-md-6 mb-4 mb-lg-0 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="staff text-center">
                <div className="mb-4">
                  <img
                    src="images/staff_3.jpg"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
                <div className="staff-body">
                  <h3 className="staff-name">Cynthia Misso</h3>
                  <span className="d-block position mb-4">Teacher English</span>
                  <p className="mb-4">
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="social">
                    <a href="#" className="mx-2">
                      <span className="icon-facebook"></span>
                    </a>
                    <a href="#" className="mx-2">
                      <span className="icon-twitter"></span>
                    </a>
                    <a href="#" className="mx-2">
                      <span className="icon-linkedin"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
