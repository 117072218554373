import React from "react";
import CountUp from "react-countup";

const CounterAnimation = ({start,end,text}) => {
  return (
    <div>
      <CountUp
        start={start}
        end={end}
        duration={2.75}
        suffix={text}
      />
    </div>
  );
};

export default CounterAnimation;
