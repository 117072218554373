import React from "react";

const Pricing = () => {
  return (
    <div className="untree_co-section">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div
            className="col-lg-7 text-center"
            data-aos="fade-up"
            data-aos-delay="0"
          >
            <h2 className="line-bottom text-center mb-4">Pricing</h2>
            <p>
              Our pricing is transparent and flexible, with customized packages
              designed to fit your budget and requirements
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6 mb-4 mb-lg-0 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="00"
          >
            <div className="pricing">
              <div className="pricing-img mb-4">
                <img
                  src="images/1x/asset-1.png"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div className="pricing-body">
                <h3 className="pricing-plan-title">Starter</h3>
                <div className="price">
                  <span className="fig">$50.99</span>
                  <span>/month</span>
                </div>
                <p className="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <p>
                  <a href="#" className="btn btn-outline-primary">
                    Get Started
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 mb-4 mb-lg-0 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="pricing">
              <div className="pricing-img mb-4">
                <img
                  src="images/1x/asset-2.png"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div className="pricing-body">
                <h3 className="pricing-plan-title">Business</h3>
                <div className="price">
                  <span className="fig">$99.99</span>
                  <span>/month</span>
                </div>
                <p className="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <p>
                  <a href="#" className="btn btn-primary">
                    Get Started
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 mb-4 mb-lg-0 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="pricing">
              <div className="pricing-img mb-4">
                <img
                  src="images/1x/asset-3.png"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div className="pricing-body">
                <h3 className="pricing-plan-title">Premium</h3>
                <div className="price">
                  <span className="fig">$199.99</span>
                  <span>/month</span>
                </div>
                <p className="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <p>
                  <a href="#" className="btn btn-outline-primary">
                    Get Started
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
