import React, { useRef } from "react";
import TextAnimator from "../Animations/TextAnimator";
import {
  allLeadsList,
  homeAboutCounterListing,
  homeAboutListing,
  homeAccordionItems,
  leadsLabels,
  testimonialItems,
} from "../_mock_data/HomeData";
import CounterAnimation from "../Animations/CounterAnimation";
import DynamicCarousel from "../Carousel/Carousel";
import DynamicAccordion from "../Accordion/DynamicAccordion";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const sectionRef = useRef(null);
  const navigate=useNavigate()

  const handleScrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className="untree_co-hero overlay"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-img-bg.jpg)`,
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-12 text-center">
                  <h1
                    className="heading text-white mb-0 mt-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    At The Originate, we redefine excellence in customer
                    engagement.
                  </h1>

                  <div className="mt-2 mb-3">
                    <TextAnimator sequence={leadsLabels} color="white" />
                  </div>

                  <p className="mb-0" data-aos="fade-up" data-aos-delay="300">
                    <a
                      href="#"
                      className="btn btn-secondary"
                      onClick={handleScrollToSection}
                    >
                      Explore More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="untree_co-section" ref={sectionRef}>
        <div className="container">
          <div className="row justify-content-center mb-3">
            <div
              className="col-lg-7 text-center"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <h2 className="line-bottom text-center mb-4">
                Boost Your Business With Quality Leads!
              </h2>
            </div>
          </div>
          <div className="row align-items-stretch">
            {allLeadsList?.map((data, index) => (
              <div
                className="col-sm-6 col-md-6 col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="0"
                key={index}
              >
                <a href="#" className="category d-flex align-items-start h-100">
                  <div>{data?.icon}</div>
                  <div>
                    <h3>{data?.name}</h3>
                    <CounterAnimation start={0} end={2000} text="Leads" />
                  </div>
                </a>
              </div>
            ))}
          </div>

          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="col-lg-8 text-center">
              <p>
                We have more category here. <a onClick={()=>navigate('/services')}>Browse all</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="untree_co-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5 mb-5">
              <h2
                className="line-bottom mb-4"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                About Us
              </h2>
              <p data-aos="fade-up" data-aos-delay="100">
                Welcome to The Originate, where excellence in customer support
                is not just a goal but a commitment we uphold every day. Our
                mission is to redefine the customer service experience through
                innovation, integrity, and a relentless pursuit of excellence.
              </p>
              <ul
                className="list-unstyled ul-check mb-5 primary"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {homeAboutListing?.map((data, index) => (
                  <li key={index}>{data?.text}</li>
                ))}
              </ul>

              <div className="row count-numbers mb-5">
                {homeAboutCounterListing?.map((data, index) => (
                  <div
                    className="col-4 col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    jey={index}
                  >
                    <span className="counter d-block">
                      <span data-number="12023">
                        <CounterAnimation
                          start={data.start}
                          end={data?.end}
                          text={data?.text}
                        />
                      </span>
                    </span>
                    <span className="caption-2">{data?.heading}</span>
                  </div>
                ))}
              </div>

              <p data-aos="fade-up" data-aos-delay="200">
                <a onClick={()=>navigate('/about')}className="btn btn-outline-primary">
                  Explore More
                </a>
              </p>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
              <img
                src="images/img-about-us.jpg"
                alt="Image"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="untree_co-section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 text-center mx-auto">
              <h3 className="line-bottom mb-4">Testimonials</h3>
              <DynamicCarousel items={testimonialItems} />
            </div>
          </div>
        </div>
      </div>

      <div className="untree_co-section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 mr-auto mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <img
                src="images/img-choose-us.avif"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3 className="line-bottom mb-4">Why Choose Us</h3>
              <p>
                At The Originate, we combine years of expertise with
                cutting-edge technology to deliver exceptional customer service
                solutions tailored to your business needs.{" "}
              </p>
              <DynamicAccordion items={homeAccordionItems} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
