import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aos from "aos";
import LandingPage from "./LandingPage/LandingPage";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Services from "./Services/Services";
import CursorAnimator from "./Animations/AnimatedCursor";

function App() {
  Aos.init();
  return (
    <>
      <CursorAnimator />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
